import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'

const content = {
  backgroundTitle: 'Other Body Surgery Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'Scar Revision',
  otherOptions: [
    // {
    //   text: 'Liposuction',
    //   to: '/procedures/liposuction',
    // },
    {
      text: 'Abdominoplasty (Tummy Tuck)',
      to: '/procedures/tummy-tuck',
    },
    {
      text: 'Skin Cancer Surgery',
      to: '/procedures/skin-cancer-surgery',
    },
    {
      text: 'Scar Revision',
      to: '/procedures/scar-revision',
    },
    {
      text: 'Mole Removal',
      to: '/procedures/mole-removal',
    },
  ],
}

export default ({ data }) => (
  <MainLayout header="light">
    <Helmet>
      <title>{content.title} - Plastic Surgery Sydney</title>
    </Helmet>

    <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
      <h1>{content.title}</h1>
      <p>{content.subtitle}</p>
      <Flex>
        <Button color="#fff" small={true} outline={true}>
          Learn more
        </Button>
      </Flex>
    </Hero>

    <Content>
      <h2>Scar Revision</h2>
      <p>
        Dr Sellars performs scar revision surgery. Depending on the site, size
        and reason for the scar, these can be treated with revision surgery
        under local anaesthetic in the rooms or sedation/general anesthetic in
        hospital.
      </p>
      <p>
        Some scars may be treated with steroid injections such as hypertrophic
        and keloid scars.
      </p>
      <h4>Scars can be caused by many factors such as:</h4>
      <ul>
        Burns
        <li>Previous surgery</li>
        <li>Traumatic injury</li>
        <li>Piercings</li>
        <li>Infections</li>
      </ul>
      <p>
        Some scars may cause not only an aesthetic issue but can sometimes cause
        functional problems, such as tethering and contractures of skin.
      </p>
      <p>
        Patients must have realistic expectations that not all scars can be
        improved, even with revision surgery.
      </p>
      
      <h4>Possible risks of any surgery include:</h4>
      <ul>
        <li>Wound infection</li>
        <li>Pain and discomfort</li>
        <li>Nausea</li>
        <li>Haematoma (an accumulation of blood around the surgical site that may require drainage)</li>
        <li>Heavy bleeding from the incisions</li>
        <li>Keloid or hypertrophic scars</li>
        <li>Slow healing</li>
        <li>Separation of the wound edges</li>
        <li>Allergies to anaesthetic agents, antiseptics, suture material or dressings</li>
        <li>Deep vein thrombosis DVT (blood clot in the leg)</li>
      </ul>

      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars' full range of surgical and non-surgical
        procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query ScarRevisionQuery {
    womanWaterfall: file(relativePath: { eq: "background-generic.jpg" }) {
      childImageSharp {
        fluid(quality: 98, maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "water-body-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
